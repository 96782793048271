define("mgw/controllers/organizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    popoverservice: Ember.inject.service(),
    searchStr: '',
    dataMap: null,
    cache: null,
    organizations: Ember.computed('model', function () {
      return this.get('model').toArray();
    }),
    selectedOrg: null,
    columnSettingsKey: Ember.computed('currentUser.activeCustomer', function () {
      return 'organizationsGridColumns-' + this.get('currentUser.activeCustomer.id');
    }),
    columns: Ember.computed(function () {
      return [{
        id: "name",
        field: "name",
        name: this.intl.t('name'),
        width: 250,
        sortable: true,
        resizable: true
      }, {
        id: "number",
        field: "number",
        name: this.intl.t('number'),
        width: 250,
        sortable: true,
        resizable: true
      }, {
        id: "country",
        field: "country",
        name: this.intl.t('country'),
        width: 250,
        sortable: true,
        resizable: true
      }, {
        id: "externalId",
        field: "externalId",
        name: this.intl.t('externalId'),
        width: 250,
        sortable: true,
        resizable: true
      }];
    }),
    actions: {
      searchChanged: function searchChanged(value) {
        this.set('searchStr', value);
      },
      didSelectItem: function didSelectItem(item) {
        this.set('selectedOrg', item);
      },
      changeCustomer: function changeCustomer(customer) {
        this.get('currentUser').changeActiveCustomer(customer);
        this.send('reload');
        return false;
      },
      editOrganization: function editOrganization(item) {
        var itemToEdit = item ? item : this.get('selectedOrg');
        if (!itemToEdit) return;
        this.get('popoverservice').openPopover({
          name: 'edit-user-organization',
          delegate: this,
          callback: 'organizationEdited',
          data: {
            organization: itemToEdit,
            organizations: this.get('organizations')
          },
          popoverClass: 'popover--floating'
        });
      },
      addOrganization: function addOrganization() {
        var userOrg = this.store.createRecord('userOrganization', {
          customer: this.get('currentUser.activeCustomer')
        });
        this.send('editOrganization', userOrg);
      },
      organizationEdited: function organizationEdited(newOrDeleted) {
        if (newOrDeleted) {
          this.send('reload');
        } else {
          this.send('refreshCache');
        }
      },
      refreshCache: function refreshCache() {
        this.set('cache', new Date());
      },
      moreBtn: function moreBtn(sender) {
        var items = [{
          name: this.intl.t('addOrganization'),
          value: 'addOrganization',
          icon: 'icon--plus'
        }];
        this.get('popoverservice').openPopover({
          name: 'select-item',
          data: items,
          callback: 'selectedMoreBtnOption',
          delegate: this,
          popoverClass: 'popover--floating',
          sender: sender
        });
      },
      selectedMoreBtnOption: function selectedMoreBtnOption(option, context) {
        this.send(option, context);
        return false;
      }
    }
  });

  _exports.default = _default;
});