define("mgw/templates/organizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BTDgIo6a",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content-with-subheader content-white\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header subheader paper-shadow\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"onChange\"],[[30,[36,0],[[32,0],\"changeCustomer\"],null]]]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"flex-grow\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"search\",\"placeHolder\",\"searchChanged\",\"classNames\",\"inputClassNames\"],[[35,3],[30,[36,2],[\"search\"],null],[30,[36,0],[[32,0],\"searchChanged\"],null],\"bar__search\",\"search-textfield search-textfield--outlined\"]]]],[2,\"\\n    \"],[11,\"button\"],[24,1,\"editSelected\"],[24,0,\"nav-btn btn\"],[16,\"disabled\",[30,[36,6],[[35,5]],null]],[4,[38,0],[[32,0],\"editOrganization\"],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"icon icon--pencil\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"\"],[12],[1,[30,[36,2],[\"editSelected\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,1,\"moreBtn\"],[24,0,\"subheader--btn--icon icon--more\"],[4,[38,0],[[32,0],\"moreBtn\",\"moreBtn\"],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,11],null,[[\"cache\",\"searchStr\",\"columnSettingsKey\",\"items\",\"classNames\",\"columns\",\"didSelectItem\",\"editOrganization\"],[[35,10],[35,3],[35,9],[35,8],\"users-listview\",[35,7],[30,[36,0],[[32,0],\"didSelectItem\"],null],[30,[36,0],[[32,0],\"editOrganization\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"customer-select\",\"t\",\"searchStr\",\"search-textfield\",\"selectedOrg\",\"not\",\"columns\",\"organizations\",\"columnSettingsKey\",\"cache\",\"organizations-grid\"]}",
    "meta": {
      "moduleName": "mgw/templates/organizations.hbs"
    }
  });

  _exports.default = _default;
});